@tailwind base;
@tailwind components;
@tailwind utilities;

ul li a.active {
  color: #0d4870 !important;
  border-bottom: #0d4870 2px solid !important;
}

.loadingPro div {
  background-color: #dadada;
  border-radius: 5px;
  animation: load 1.1s infinite;
}
